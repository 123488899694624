<script setup>
import { onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import ImageLogo from "@components/images/ImageLogo.vue";
import Network from "@components/modals/Network.vue";
import SearchBar from "@components/search_bars/SearchBar.vue";
import NavigationMenu from "@components/modals/NavigationMenu.vue";
import NavigationMenuCollapse from "@components/modals/NavigationMenuCollapse.vue";
import UserMenu from "@components/auth/UserMenu.vue";
import { useBrandsStore } from "@composables/use_brands_store";
import { useInventoryStore } from "@composables/use_inventory_store";
import logoBaspack from "/src/assets/img/logo_baspack.png";
import logoBasterboom from "/src/assets/img/logo_basterboom.png";
import logoCorbas from "/src/assets/img/logo_corbas_wp.png";
import { useAuthStore } from "@composables/use_auth_store";
import { useShoppingCartStore } from "@composables/use_shopping_cart_store";
import Icon from "@components/icons/Icon.vue";

import { useTextBannersStore } from "@composables/use_textbanners_store";

import { Vue3Marquee } from "vue3-marquee";
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";

const { shoppingCartItemCount } = useShoppingCartStore();

const router = useRouter();
const route = useRoute();
const { brands } = useBrandsStore();

const {
  criteria,
  setCategory,
  setSubcategory,
  setCriteria,
  getProductBySubcategory,
  searchProduct,
  clearSearch,
} = useInventoryStore();

const { token, cliente } = useAuthStore();

const { getTextBanners } = useTextBannersStore();

const NavItem = [{ name: "Home", link: "/" }];

const textBanners = ref([]);
var arrTexto = [];

onMounted(() => {
  setTextBanners();
  console.log("guest header mounted");
  for (let i = 0; i < textBanners.value.length; i++) {
    arrTexto.push(textBanners.value[i].texto);
  }
});

async function setTextBanners() {
  textBanners.value = await getTextBanners();
}

function goTo(link = "Home") {
  let btnClearSearch = document.getElementById("btnClearSearch");
  if (btnClearSearch) {
    btnClearSearch.click();
  }
  router.push({
    name: link,
  });
}

function onSearch(criteria) {
  let cat_url_seg_arr;
  let sub_url_seg_arr;

  setCategory(null);
  setSubcategory(null);
  setCriteria(criteria);

  switch (route.name) {
    case "Category":
      cat_url_seg_arr = route.params.category_id_slug.split("_");
      setCategory(cat_url_seg_arr[0]);
      searchProduct();
      break;
    case "Subcategory":
      cat_url_seg_arr = route.params.category_id_slug.split("_");
      setCategory(cat_url_seg_arr[0]);
      sub_url_seg_arr = route.params.subcategory_id_slug.split("_");
      setSubcategory(sub_url_seg_arr[0]);
      if (criteria) {
        searchProduct();
      } else {
        clearSearch();
        getProductBySubcategory(cat_url_seg_arr[0], sub_url_seg_arr[0]);
      }
      break;
    default: // Home, AboutUs, Branches, FrequentQuestions, Shipments, TermsAndConditions
      searchProduct();
      break;
  }
}
</script>

<template>
  <!-- Guest header -->
  <header class="fixed-nav">
    <carousel
      v-if="textBanners != ''"
      :items-to-show="1"
      :autoplay="8000"
      :wrapAround="true"
      class="text-white pt-0 mt-0"
      style="background-color: #333"
    >
      <slide
        v-for="(textBanner, index) in textBanners"
        :key="index"
        class="max-height: 25px;"
      >
        <em
          ><a
            class="text-white"
            :href="textBanner.link == '' ? '#' : textBanner.link"
            target="_blank"
            >{{ textBanner.texto }}</a
          ></em
        >
      </slide>
    </carousel>

    <nav>
      <div class="px-3 border">
        <div class="row justify-content-between align-items-center">
          <!-- Image logos -->

          <div
            class="col-12 px-3 mx-3 col-sm-11 col-md-12 mx-md-5 col-lg-5 px-lg-4 mx-lg-0 col-xl-3 col-xxl-3"
          >
            <div
              class="row justify-content-start align-items-center"
              style="border-radius: 15px"
            >
              <ImageLogo
                :classes="'col-2 col-sm-3 px-sm-4 col-md-3 px-md-5 py-lg-1 px-lg-1 col-lg-3 col-xl-2 col-xxl-2 max-w-80 max-h-34'"
                :source="logoBaspack"
                @ontap="goTo"
              />
              <ImageLogo
                :classes="'col-3 col-sm-3 px-sm-4 px-md-4 px-lg-1 col-lg-4 max-w-80 max-h-34'"
                :source="logoBasterboom"
                @ontap="goTo"
              />
              <ImageLogo
                :classes="'col-3 col-sm-3 px-sm-4 col-md-2 px-md-4 px-lg-1 col-lg-2 max-w-80 max-h-34'"
                :source="logoCorbas"
                @ontap="goTo"
              />
              <Network
                class="col-3 col-sm-3 p-2 px-sm-4 px-md-4 px-lg-1 col-lg-3 max-w-80 max-h-44"
                style="margin-left: 13px"
                :type="'whatsapp'"
                :brands="brands"
              />
            </div>
          </div>

          <!-- section in red -->
          <div
            class="row col-12 mx-0 col-xxl-9 col-xl-9 col-lg-7 align-items-center py-2 py-xxl-3"
            style="background-color: #e41b1b"
          >
            <!-- menu collapse -->
            <div class="col-2 px-0 col-md-1 col-lg-1 d-xl-none">
              <NavigationMenuCollapse :items="NavItem" :showCart="true" />
            </div>

            <!-- search -->
            <div
              class="col-7 px-auto col-md-9 col-lg-8 col-xl-5 col-xxl-6 py-md-1"
            >
              <SearchBar :criteria="criteria" @onsearchbarchange="onSearch" />
            </div>

            <!-- menu -->
            <div class="d-none col-2 d-xl-block col-xl-auto">
              <NavigationMenu
                :classes="'col-auto'"
                :items="NavItem"
                :showCart="true"
              />
            </div>

            <!-- user -->

            <div v-if="token" class="d-none d-xl-block col-auto px-5">
              <UserMenu :cliente="cliente" />
            </div>

            <!-- cart icon -->
            <div
              class="col-2 col-xxl-auto col-xl-auto col-lg-2 col-md-auto col-sm-2 text-end"
            >
              <a
                class="col-auto my-2 position-relative"
                :href="`${shoppingCartItemCount > 0 ? '/shopping_cart' : '#'}`"
              >
                <Icon :name="'shopping_cart'" :width="30" class="px-0" />
                <span
                  v-if="shoppingCartItemCount > 0"
                  class="position-absolute badge rounded-pill bg-white text-dark location"
                  style="left: 1.3rem"
                  >{{ shoppingCartItemCount }}
                </span>
              </a>
            </div>

            <!-- </div> -->
          </div>
        </div>
      </div>
    </nav>
  </header>
</template>

<style scoped>
.fixed-nav {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 150;
  background-color: white;
}
.userLabel {
  color: rgb(60, 60, 163);
  font-size: 12px;
}
</style>
